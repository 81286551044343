<template>
  <div>
    <b-modal
      no-close-on-backdrop
      @hidden="$emit('hidden')"
      ref="modal-add-shipping-times"
      size="lg"
      :title="title + ' SHIPPING TIMES'"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="origin"
              >
                <b-form-group :class="errors[0]" label="ORIGIN">
                  <v-select
                    v-model="dataShippingTimes.originId"
                    label="state"
                    :options="dataOrigin"
                    :reduce="(option) => option.id"
                  >
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="destination"
              >
                <b-form-group :class="errors[0]" label="DESTINATION">
                  <v-select
                    v-model="dataShippingTimes.destinationId"
                    label="state"
                    :options="dataDestination"
                    :reduce="(option) => option.id"
                  >
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="days"
              >
                <b-form-group label="DAYS">
                  <b-form-input
                    v-mask="'###'"
                    :class="errors[0]"
                    v-model="dataShippingTimes.days"
                  >
                  </b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button class="" @click="cancel()">CANCEL</b-button>
        </div>
        <div>
          <b-button variant="primary" @click="setAction()">
            <feather-icon icon="SaveIcon" class="mr-1" />
            {{ textButton }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ShippingDaysService from "@/views/commons/components/settings/views/components/ShippingDaysTab/service/shipping-days.service";
import { mapGetters } from "vuex";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import VueMask from "v-mask";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    VueAutosuggest,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    VueMask,
  },
  props: {
    dataShipping: {
      type: Object,
      required: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "ADD",
      textButton: "SAVE",
      dataOrigin: [],
      dataDestination: [],
      statusShipping: [
        { id: 1, label: "Active" },
        { id: 2, label: "Inactive" },
      ],

      dataShippingTimes: {
        id: "",
        originId: "",
        origin: "",
        destination: "",
        destinationId: "",
        days: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-add-shipping-times");
  },
  created() {
    this.getStates();
    if (this.dataShipping) {
      if (this.dataShipping.id) {
        this.showShippingTimes();
        this.title = "UPDATE";
        this.textButton = "UPDATE";
      }
    }
  },
  methods: {
    setAction() {
      if (this.isUpdate) {
        if (this.dataShipping.id) {
          this.updateShippingTimes();
        }
      } else {
        this.createShippingTimes();
      }
    },
    async createShippingTimes() {
      const result = await this.$refs.observer.validate();
      if (result) {
        try {
          const params = {
            originId: this.dataShippingTimes.originId,
            destinationId: this.dataShippingTimes.destinationId,
            days: this.dataShippingTimes.days,
            id_user: this.currentUser.user_id,
          };

          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await ShippingDaysService.createShippingTimes(params);
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.$emit("refreshTable");
              this.$emit("closeModalShippingTimes");
            }
          }
        } catch (error) {
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info");
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
    async getStates() {
      try {
        const { data } = await ShippingDaysService.getStates();
        this.dataOrigin = data;
        this.dataDestination = data;
      } catch (error) {
        console.log(error);
      }
    },
    showShippingTimes() {
      this.dataShippingTimes.originId = this.dataShipping.originId;
      this.dataShippingTimes.destinationId = this.dataShipping.destinationId;

      this.dataShippingTimes.origin = this.dataShipping.originState;
      this.dataShippingTimes.destination = this.dataShipping.destinationState;

      this.dataShippingTimes.days = this.dataShipping.days;
      this.dataShippingTimes.id = this.dataShipping.id;
    },
    async updateShippingTimes() {
      const result = await this.$refs.observer.validate();

      if (parseInt(this.dataShippingTimes.days) <= 0) {
        this.showInfoSwal("THE DAYS CANNOT BE LESS THAN 0");
      } else {
        if (result) {
          try {
            const params = {
              originId: this.dataShippingTimes.originId,
              destinationId: this.dataShippingTimes.destinationId,
              days: this.dataShippingTimes.days,
              id_user: this.currentUser.user_id,
              id_shipping: this.dataShippingTimes.id,
            };

            const resultState = await this.showConfirmSwal();
            if (resultState.isConfirmed) {
              this.addPreloader();
              const resul = await ShippingDaysService.updateShippingTimes(
                params
              );
              if (resul.status == 200) {
                this.showSuccessSwal();
                this.$emit("refreshTable");
                this.$emit("closeModalShippingTimes");
              }
            }
          } catch (error) {
            if (error.response?.data?.error) {
              this.showInfoSwal(error.response?.data?.error, "Info");
            } else {
              this.showErrorSwal(error);
            }
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    cancel() {
      this.$emit("closeModalShippingTimes");
    },
  },
};
</script>