// ADVANCED FILTERS TO TABLEBANKACCOUNTS
export default [
  {
    // [0]
    type: "suggest",
    margin: true,
    showLabel: true,
    label: "Origin",
    showText: "Suggest",
    placeholder: "Origin",
    locale: "en",
    options: [],
    selectText: 'state',
    model: null,
    typeQuery: true,
    apiUrl: '/debt-solution/shipping-days/search-states-suggestion',
    cols: 6
  },
  {
    // [1]
    type: "suggest",
    margin: true,
    showLabel: true,
    label: "Destination",
    showText: "Suggest",
    placeholder: "Destination",
    locale: "en",
    selectText: 'state',
    options: [],
    model: null,
    typeQuery: true,
    apiUrl: '/debt-solution/shipping-days/search-states-suggestion',
    cols: 6
  },
  {
    // [2]
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Created from",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    // [3]
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Created to",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  }
];
