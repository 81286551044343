// FIELDS OF TABLEBANKACCOUNT
export default[
    {
        key: "origin",
        visible: true,
    },
    {
        key: "destination",
        visible:true
    },
    {
        key: "days_to_delivery",
        thClass: "text-center",
        tdClass: "text-center",
        visible:true
    },
    {
        key: "created_by",
        visible: true
    },
    {
        key: "created_at",
        visible: true
    },
    {
        key: "actions",
        visible:true
    },

]