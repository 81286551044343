<template>
  <div>
    <!-- HEADER SLOT BUTTONS HERE WITH PORTALS -->
    <portal to="actionsButtons_header">
      <button
        v-if="$route.name === 'ds-shipping-days-table'"
        type="button"
        class="btn btn-success"
        @click="openModalShippingTimes([],false)"
        v-b-tooltip.hover.left="'CREATE'"
      >
        +
      </button>
    </portal>
    <portal to="actions_header">
      <button
        v-if="$route.name === 'ds-shipping-days-table'"
        type="button"
        class="btn btn-success"
        @click="openModalShippingTimes([],false)"
      >
        + CREATE
      </button>
    </portal>
    <!-- END HEADER SLOT BUTTONS -->

    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="paginate.startPage"
      :to-page="paginate.toPage"
      @reload="$refs['shippingDaysTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <template #table>
        <b-table
          ref="shippingDaysTable"
          no-border-collapse
          show-empty
          sticky-header="50vh"
          class="position-relative"
          table-class="text-nowrap"
          :items="myProvider"
          :fields="visibleFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy.sync="isBusy"
          :filter="searchInput"
          empty-text="No records to view..."
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(created_at)="data">
            <span>
              {{ data.item.created_at | myGlobalDay }}
            </span>
          </template>

          <template #cell(delete_at)="data">
            <span>
              {{ data.item.delete_at | myGlobalDay }}
            </span>
          </template>

          <template #cell(actions)="data">
            <feather-icon
              v-b-tooltip.hover.top="'Edit Shipping Day'"
              icon="EditIcon"
              size="18"
              class="cursor-pointer text-warning mr-1"
              @click="openModalShippingTimes(data.item,true)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <ModalShippingTimes
      v-if="showModal"
      @hidden="closeModalShippingTimes"
      :isUpdate="isUpdate"
      :dataShipping="dataUpdate"
      @closeModalShippingTimes="closeModalShippingTimes"
      @refreshTable="reloadTable"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
//  /components/ModalShippingTimes.vue
import ModalShippingTimes from "@/views/commons/components/settings/views/components/ShippingDaysTab/components/ModalShippingTimes.vue";
import ShippingDaysFields from "@/views/commons/components/settings/views/components/ShippingDaysTab/data/shipping-days.fields";
import ShippingDaysFilters from "@/views/commons/components/settings/views/components/ShippingDaysTab/data/shipping-days.filters";
import ShippingDaysService from "@/views/commons/components/settings/views/components/ShippingDaysTab/service/shipping-days.service";

export default {
  components: { ModalShippingTimes },
  data() {
    return {
      dataUpdate: {
        originState: "",
        destinationState: "",
        id: 0,
        originId: "",
        destinationId: "",
        days: "",
        status: "",
      },
      isUpdate: false,
      showModal: false,
      fields: ShippingDaysFields,
      filter: ShippingDaysFilters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
        startPage: 0,
        toPage: 0,
      },
      searchInput: "",
      sortBy: "",
      sortDesc: true,
      isBusy: false,
      deleteOrNot: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    async myProvider() {
      try {
        let params = {
          deleteOrNot: this.deleteOrNot,
          perPage: this.paginate.perPage,
          npage: this.paginate.currentPage,
          campo: this.filterPrincipal.model,
          origin: this.filter[0].savedValue,
          destination: this.filter[1].savedValue,
          from: this.filter[2].model,
          to: this.filter[3].model,
        };
        const { data, status } = await ShippingDaysService.getShippingDays(
          params
        );
        if (status == 200) {
          this.totalRows = data.total;
          if (data.from) {
            this.paginate.startPage = data.from;
          } else {
            this.paginate.startPage = 0;
          }
          if (data.to) {
            this.paginate.toPage = data.to;
          } else {
            this.paginate.toPage = 0;
          }
          return data.data || [];
        }
      } catch (error) {
        throw error;
      }
    },
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
    },
    openModalShippingTimes(params, isUpdate) {
      this.isUpdate = isUpdate
      this.showModal = true;
      this.dataUpdate.id = params.id;
      this.dataUpdate.originId = params.state_origin_id;
      this.dataUpdate.destinationId = params.state_destination_id;
      this.dataUpdate.days = params.days_to_delivery;
      this.dataUpdate.originState = params.origin;
      this.dataUpdate.destinationState = params.destination;
    },
    closeModalShippingTimes() {
      this.isUpdate = false
      this.showModal = false;
    },
    reloadTable() {
      this.$refs["shippingDaysTable"].refresh();
    },
  },
};
</script>
