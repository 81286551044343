import { amgApi } from "@/service/axios";

class ShippingDaysService {

    async createShippingTimes(params) {
        try {
            const data = await amgApi.post('/debt-solution/shipping-days/set-shipping-times', params)
            return data
          } catch (error) {
            console.log('Something went wrong on getUserModule:', error)
            throw error
          }
    }

    async updateShippingTimes(params) {
        try {
            const data = await amgApi.post('/debt-solution/shipping-days/update-shipping-times', params)
            return data
          } catch (error) {
            console.log('Something went wrong on getUserModule:', error)
            throw error
          }
    }

    async getShippingDays(params){
        try{
            const data = await amgApi.post('/debt-solution/shipping-days/get-shipping-days',params)
            return data
        }catch(error){
            throw error
        }
    }

    async getStates(){
        try{
            const data = await amgApi.post('/debt-solution/shipping-days/search-states')
            return data
        }catch(error){
            throw error
        }
    }

}

export default new ShippingDaysService()
